import React, { useEffect, useState } from "react";
import { getAuthToken } from "../../firebase";
import "../../tailwind.generated.css";

import Button from "../Button";

import date from "date-and-time";

const Tests = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tests, setTests] = useState([]);
  const [paginationCursor, setPaginationCursor] = useState(undefined);
  const [isForbidden, setIsForbidden] = useState(false);

  const fetchTests = async () => {
    const authToken = await getAuthToken();
    const response = await fetch(`/api/admin/getTests`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Barrier ${authToken}`,
      },
      body: JSON.stringify({ paginationCursor: paginationCursor }),
    });
    const data = await response.json();
    if (response.ok) {
      setTests([...tests, ...data.tests]);
      setPaginationCursor(data.paginationCursor);
    }
    else if (response.status === 403) {
      setIsForbidden(true);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchTests();
  }, []);

  if (isForbidden) {
    return <div className="container mx-auto w-full pb-6">
      <span className="text-3xl font-bold block my-8">No access</span>
    </div>;
  }

  return (
    <div className="container mx-auto w-full pb-6">
      <span className="text-3xl font-bold block my-8">Recent tests</span>
      <div className="grid grid-cols-6 my-4 gap-4 text-gray-600 text-sm font-medium">
        <div>Name</div>
        <div>Responses</div>
        <div>Status</div>
        <div>ID</div>
        <div>Created at</div>
        <div>Author</div>
      </div>
      {tests
        .sort((a, b) => b.data.createdAt._seconds - a.data.createdAt._seconds)
        .map((test) => {
          return (
            <div className="grid grid-cols-6 my-4 gap-4 text-sm" key={test.testId}>
              <div>{test.data.name}</div>
              <div>{test.data.answersCounter}</div>
              <div>{test.data.status}</div>
              <div>{test.testId}</div>
              <div>
                {date.format(
                  new Date(test.data.createdAt._seconds * 1000),
                  "D MMM, HH:mm"
                )}
              </div>
              <div>{test.author.displayName}</div>
            </div>
          );
        })}
      {!isLoading && (
        <Button
          name="Load more"
          type="secondary"
          className="my-6 mx-auto"
          small
          handler={() => {
            setIsLoading(true);
            fetchTests();
          }}
        />
      )}
    </div>
  );
};

export default Tests;
